import React, { FC, ComponentProps, useCallback, Fragment } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocale } from 'src/context';
import { OrderItemFragmentFragment, OrderItemFieldsFragmentFragment } from 'src/graphql';
import styled from 'styled-components';

const Container = styled.section`
  margin-left: ${(p) => p.theme.scale(0)};
  margin-right: ${(p) => p.theme.scale(2)};

  & + & {
    border-top: solid 2px ${({ theme }) => theme.color.dark['0']};
    margin-top: ${(p) => p.theme.scale(-3)};
    padding-top: ${(p) => p.theme.scale(-3)};
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
`;

const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(p) => p.theme.scale(1.5)};
  width: ${(p) => p.theme.scale(1.5)};
  border: solid 1px ${({ theme }) => theme.color.dark['0']};
  color: ${(p) => p.theme.color.dark['0']};

  font-size: ${(p) => p.theme.scale(0.5)};
  font-weight: bold;

  &[data-inverse='true'] {
    background: ${(p) => p.theme.color.dark['0']};
    color: ${(p) => p.theme.color.light['0']};
  }
`;

const Title = styled.h3`
  margin: 0;
  margin-left: ${(p) => p.theme.scale(0)};
  font-size: ${(p) => p.theme.scale(0)};
`;

const ItemPrice = styled.p`
  margin: 0;
  margin-left: auto;
  margin-right: ${(p) => p.theme.scale(0.5)};
  font-weight: bold;
  font-size: ${(p) => p.theme.scale(0)};
`;

const ModifierPrice = styled.span`
  color: ${(p) => p.theme.color.grey['-3']};
  margin-left: auto;
  margin-right: ${(p) => p.theme.scale(0.5)};
`;

const ItemList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 0;
`;

const Item = styled.li`
  --indentation: 20px;
  display: flex;
  font-size: ${(p) => p.theme.scale(0)};
  line-height: 2em;

  &:nth-child(odd) {
    background: ${({ theme }) => theme.color.light['-4']};
  }

  &[data-depth='0'] {
    padding-left: calc(${(p) => p.theme.scale(3.5)} + var(--indentation) * 0);
  }

  &[data-depth='1'] {
    padding-left: calc(${(p) => p.theme.scale(3.5)} + var(--indentation) * 1);
  }

  &[data-depth='2'] {
    padding-left: calc(${(p) => p.theme.scale(3.5)} + var(--indentation) * 2);
  }
`;

type ItemBreakdownProps = ComponentProps<typeof Container> & {
  productOrder: OrderItemFragmentFragment;
};

type OrderItemOptionalChild = OrderItemFieldsFragmentFragment & {
  children?: readonly OrderItemOptionalChild[];
  lineId?: string;
};

export const ItemBreakdown: FC<ItemBreakdownProps> = ({ productOrder, ...props }) => {
  const { enableItemOptionModifierDetail } = useFlags();
  const { formatNumber, currency } = useLocale();

  const renderItemChildren = useCallback(
    ({ item, depth = 0 }: { item: OrderItemOptionalChild; depth?: number }) => {
      if (!item.children || item.children.length === 0 || !item.visible) {
        return null;
      }

      return (
        <>
          {item.children.map((child) => {
            if (child.lineId === 'loyalty' && !child.children?.length) {
              return null;
            }

            // When an offer has to show modifiers,
            // it needs to be listed as a combo but we don't want to display the combo text
            if (child.name.includes('Combo') && !child.children?.length) {
              return <Fragment key={`${child.name}-${depth}`} />;
            }
            if (child.type === 'ComboSlot' || child.type === 'ItemOption') {
              return (
                <Fragment key={`${child.name}-${depth}`}>
                  {child.children && renderItemChildren({ item: child, depth })}
                </Fragment>
              );
            }

            const modifierPrice = child.price !== -1 && (
              <ModifierPrice>
                +
                {formatNumber(child.price / 100, {
                  style: 'currency',
                  currency,
                })}
              </ModifierPrice>
            );

            let childItemName = child.name;
            /**
             * LD Flag: enable-item-option-modifier-detail
             * Display the correct name for ItemOptionModifier.
             * ItemOptionModifier only displays the selected modification
             * (eg: "Extra", "Large") for an ItemOption item (eg: "Tomato", "Bacon").
             */
            if (enableItemOptionModifierDetail && child.type === 'ItemOptionModifier') {
              childItemName = `${child.name} (${item.name})`;
            }

            return (
              <Fragment key={`${child.name}-${depth}`}>
                <Item data-depth={depth} key={childItemName}>
                  {childItemName}
                  {modifierPrice}
                </Item>
                {child.children &&
                  renderItemChildren({
                    item: child,
                    depth: depth + 1,
                  })}
              </Fragment>
            );
          })}
        </>
      );
    },
    [currency, enableItemOptionModifierDetail, formatNumber],
  );

  return (
    <Container {...props}>
      <Heading>
        <Quantity data-inverse={productOrder.quantity > 1}>
          {formatNumber(productOrder.quantity)}
        </Quantity>
        <Title>{productOrder.name}</Title>
        <ItemPrice>
          {formatNumber(productOrder.price / 100, {
            style: 'currency',
            currency,
          })}
        </ItemPrice>
      </Heading>
      <ItemList>{renderItemChildren({ item: productOrder })}</ItemList>
    </Container>
  );
};
