import { useState, useEffect, useCallback } from 'react';

export const useInternetConnectivityStatus = () => {
  const isOnline = window.navigator.onLine;
  const [isConnectionOnline, setIsConnectionOnline] = useState(isOnline);

  const handleIsOnline = useCallback(() => setIsConnectionOnline(true), []);

  const handleIsOffline = useCallback(() => setIsConnectionOnline(false), []);

  useEffect(() => {
    // Add event listener to detect network loss
    window.addEventListener('offline', handleIsOffline);

    // Add event listener to detect network recovery
    window.addEventListener('online', handleIsOnline);

    return () => {
      window.removeEventListener('offline', handleIsOffline);
      window.removeEventListener('online', handleIsOnline);
    };
  }, [handleIsOnline, handleIsOffline]);

  return isConnectionOnline;
};
