
      export type IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    