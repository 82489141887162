import React, { useRef, useEffect, FC, MutableRefObject } from 'react';

// dismiss modal on clicks outside of modal and on press of ESC key
const useEscapeAndOutsideClickDetection = (
  ref: MutableRefObject<HTMLElement | null>,
  dismissCallback: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target instanceof Element && ref.current && !ref.current.contains(e.target)) {
        dismissCallback();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dismissCallback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ref, dismissCallback]);
};

interface ICloseFunctionalityWrapper {
  dismissCallback: () => void;
}

export const CloseFunctionalityWrapper: FC<ICloseFunctionalityWrapper> = ({
  children,
  dismissCallback,
}) => {
  const wrapperRef = useRef(null);
  useEscapeAndOutsideClickDetection(wrapperRef, dismissCallback);

  return <div ref={wrapperRef}>{children}</div>;
};
