import React, { FC } from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../icons';

const IconButton = styled.button`
  border: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.color.dark['+6']}; // this color will be the color of the "X"
`;

const CircleOutline = styled.div`
  box-shadow: 1px 1px 2px ${({ theme }) => theme.color.light['-3']},
    -1px 1px 2px ${({ theme }) => theme.color.light['-6']},
    1px -1px 2px ${({ theme }) => theme.color.light['-6']},
    -1px -1px 2px ${({ theme }) => theme.color.light['-6']};
  border-radius: 50%;
  padding: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.light['0']};
`;

interface IModalCloseIconProps {
  onIconClick: () => void;
}

export const ModalCloseIcon: FC<IModalCloseIconProps> = ({ onIconClick, ...props }) => {
  return (
    <IconButton
      aria-label="close modal"
      onClick={onIconClick}
      {...props}
      data-testid="modal-close-icon"
    >
      <CircleOutline>
        <CloseIcon />
      </CircleOutline>
    </IconButton>
  );
};
