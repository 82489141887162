import React, { FC } from 'react';
import styled from 'styled-components';

const ModalHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 14px;
`;

export const ModalHeader: FC = ({ children, ...props }) => {
  return <ModalHeaderContainer {...props}>{children}</ModalHeaderContainer>;
};
