import React, { FC, ReactNode, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useBrand } from 'src/context';
import BKThemeProvider from './Theme.bk';
import PLKThemeProvider from './Theme.plk';
import THThemeProvider from './Theme.th';
import 'react-toastify/dist/ReactToastify.css';

const themes = {
  bk: BKThemeProvider,
  plk: PLKThemeProvider,
  th: THThemeProvider,
} as const;

export const ThemeProvider: FC<{ children: ReactNode }> = (props) => {
  const brand = useBrand();
  const Provider = useMemo(() => themes[brand], [brand]);

  return (
    <>
      <Provider {...props} />
      <GlobalStyle />
    </>
  );
};

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
  }

  html, body, #root {
    margin: 0;
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`;

export type Theme = typeof import('./Theme.bk').theme;
