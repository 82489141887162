/* eslint-disable consistent-return */ // This entire file has been changed with the pin update and this disable will not exist

import { useApolloClient } from '@apollo/react-hooks';
import React, { FC, ComponentProps, lazy, Suspense, useMemo, useState } from 'react';
import { Spinner } from 'src/components/Spinner';
import { Button, Input, InputError } from 'src/components';
import { GetStoreDocument, GetStoreQuery, GetStoreQueryVariables } from 'src/graphql';
import styled from 'styled-components';
import { useBrand, useLocale, useStoreContext } from 'src/context';
import { useFormik, useFormikContext, useField, FormikProvider } from 'formik';
import { Page, Container, Title, Form } from '../styled';
import { debounce } from '../../../utils/debounce';

const StyledSpinner = styled(Spinner)`
  margin-bottom: 0;
  width: 1.1em;
  height: 1.1em;
`;

type FormValues = {
  id: string;
};

const isRestaurantIdANumber = (id: string) => !Number.isNaN(Number(id));

const logos = {
  bk: lazy(() => import('../Logo.bk')),
  plk: lazy(() => import('../Logo.plk')),
  th: lazy(() => import('../Logo.th')),
} as const;

export const ConfigureStore: FC<ComponentProps<typeof Page>> = (props) => {
  const { formatMessage } = useLocale();
  const brand = useBrand();
  const client = useApolloClient();
  const { setStoreId } = useStoreContext();
  const [showSpinner, setShowSpinner] = useState(false);

  const Logo = useMemo(() => logos[brand], [brand]);

  const initialValues: FormValues = {
    id: '',
  };

  const onSubmit = (values: FormValues) => {
    setStoreId(values.id);
  };

  const validate = async (values: FormValues) => {
    const spinnerDelay = 500;
    const spinnerDelayTimer = setTimeout(() => setShowSpinner(true), spinnerDelay);

    const errors: {
      id?: null | string;
    } = {};

    const storeIdError = await (async () => {
      if (!values.id) {
        errors.id = formatMessage({ id: 'config.error.requiredField' });
        return;
      }

      if (!isRestaurantIdANumber(values.id)) {
        errors.id = formatMessage({ id: 'config.error.invalidIdEntry' });
        return;
      }

      try {
        const res = await client.query<GetStoreQuery, GetStoreQueryVariables>({
          query: GetStoreDocument,
          variables: { id: values.id },
        });

        if (res?.data?.store === null) {
          return formatMessage({ id: 'config.error.not-found' });
        }

        if (!res?.data?.store) {
          return formatMessage({ id: 'config.error.unexpected' });
        }
      } catch (err) {
        return formatMessage({ id: 'config.error.unexpected' });
      }
    })();

    if (storeIdError) {
      errors.id = storeIdError;
    }

    clearTimeout(spinnerDelayTimer);
    setShowSpinner(false);

    return errors;
  };

  const formik = useFormik<FormValues>({
    onSubmit,
    initialValues,
    validate,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
  });

  return (
    <Page {...props}>
      <Container>
        <Suspense fallback={null}>
          <Logo
            style={{
              width: '10em',
              margin: '0 auto',
              height: 'min-content',
              paddingBottom: '2rem',
            }}
          />
        </Suspense>
        <Title>{formatMessage({ id: 'config.title' })}</Title>
        <FormikProvider value={formik}>
          <FormContent showButtonSpinner={showSpinner} />
        </FormikProvider>
      </Container>
    </Page>
  );
};

interface FormContentProps {
  showButtonSpinner: boolean;
}

const FormContent: FC<FormContentProps> = ({ showButtonSpinner, ...props }) => {
  const { handleSubmit, validateForm } = useFormikContext();
  const [fieldProps, fieldMeta] = useField({ name: 'id' });
  const { formatMessage } = useLocale();

  // disable button if there are errors, or if no input, or if input is validating
  const isButtonDisabled = !!fieldMeta.error || !fieldMeta.value || showButtonSpinner;

  const buttonContent = showButtonSpinner ? (
    <StyledSpinner appearance="light" />
  ) : (
    formatMessage({ id: 'config.continue' })
  );

  const debouncedValidate = useMemo(() => debounce(() => validateForm(), 400), [validateForm]);

  return (
    <Form onSubmit={handleSubmit} {...props}>
      <Input
        invalid={Boolean(fieldMeta.error)}
        placeholder={formatMessage({ id: 'config.id.placeholder' })}
        {...fieldProps}
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          // Trigger the form validation only after user finishes inputting the store ID
          debouncedValidate();

          // Update the field value
          fieldProps.onChange(event);
        }}
      />

      {fieldMeta.error && <InputError>{fieldMeta.error}</InputError>}

      <Button disabled={isButtonDisabled} type="submit" kind="primary">
        {buttonContent}
      </Button>
    </Form>
  );
};
