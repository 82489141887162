import React, { FC } from 'react';
import styled from 'styled-components';

const ModalContentWrapper = styled.div`
  overflow-y: scroll;
  padding: 20px 28px 40px;
`;

export const ModalBody: FC = ({ children, ...props }) => {
  return <ModalContentWrapper {...props}>{children}</ModalContentWrapper>;
};
