import styled from 'styled-components';

type InputErrorProps = JSX.IntrinsicElements['p'];

export const InputError = styled.p.attrs<InputErrorProps>((props) => ({
  role: props.role || 'alert',
  ...props,
}))`
  color: ${(p) => p.theme.color.red['0']};

  input + & {
    margin-top: -${(p) => p.theme.scale(0)};
  }
`;
