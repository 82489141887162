import * as React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useStoreContext } from 'src/context';
import { useSendHeartbeatMutation } from 'src/graphql';

export const Heartbeat: React.FC = ({ children }) => {
  const { storeId } = useStoreContext();

  const { heartbeatIntervalMs } = useFlags();

  const [sendHeartbeat] = useSendHeartbeatMutation();

  // fire heartbeat immediately on mount
  React.useEffect(() => {
    sendHeartbeat({ variables: { storeId } });
  }, []); // eslint-disable-line

  // set up interval for subsequent heartbeats
  React.useEffect(() => {
    const intervalId = setInterval(
      () => sendHeartbeat({ variables: { storeId } }),
      heartbeatIntervalMs,
    );
    return () => clearInterval(intervalId);
  }, [sendHeartbeat, storeId, heartbeatIntervalMs]);

  return <>{children}</>;
};
