import React, { FC } from 'react';
import { useLocale } from 'src/context';
import { ModalHeader } from '../ModalHeader';
import { ModalCloseIcon } from '../ModalCloseIcon';

import { CenteredModalBody, ErrorModalHeading, ErrorModalButton, ErrorModal } from './styled';

interface IInternetConnectivityErrorModalProps {
  isModalOpen: boolean;
  onDismiss: () => void;
}

export const InternetConnectivityErrorModal: FC<IInternetConnectivityErrorModalProps> = ({
  isModalOpen,
  onDismiss,
}) => {
  const { formatMessage } = useLocale();
  const refreshPage = () => {
    window.location.reload();
    return false;
  };
  return (
    <ErrorModal
      data-snapshot
      data-testid="connectivity-error-modal"
      isModalOpen={isModalOpen}
      onDismiss={onDismiss}
      size="small"
    >
      <ModalHeader>
        <ModalCloseIcon onIconClick={onDismiss} />
      </ModalHeader>
      <CenteredModalBody>
        <ErrorModalHeading>
          {formatMessage({ id: 'error-modal.network-error.heading' })}
        </ErrorModalHeading>
        <p>{formatMessage({ id: 'error-modal.network-error.message' })}</p>
        <ErrorModalButton data-testid="refresh-button" kind="secondary" onClick={refreshPage}>
          {formatMessage({ id: 'error-modal.network-error.button-text' })}
        </ErrorModalButton>
      </CenteredModalBody>
    </ErrorModal>
  );
};
