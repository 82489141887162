import {
  MARKETS,
  REGIONS as GENERATED_REGIONS,
  CURRENCIES as GENERATED_CURRENCIES,
  LOCALES as GENERATED_LOCALES,
} from '../../generated/markets';
import Schema from './dictionaries/en.json';

// Keep in sync with GraphQL Locales
export type Locale = typeof LOCALES[number];

export type Region = typeof REGIONS[number];

export type Currency = typeof CURRENCIES[number];

export type LocalizedDictionary = Record<keyof typeof Schema, string>;

export const LOCALES = GENERATED_LOCALES;

export const REGIONS = GENERATED_REGIONS;

export const CURRENCIES = GENERATED_CURRENCIES;

export const RegionToCurrencyMap: Record<string, Currency> = Object.fromEntries(
  MARKETS.map(({ regionCode, regionCurrency }) => [regionCode, regionCurrency]),
);
