/* eslint-disable */
import { ApolloProvider as ApolloClientProvider } from '@apollo/react-hooks';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  ApolloClient,
  HttpLink,
  from,
  split,
} from 'apollo-boost';
import React, { FC, ReactNode, useLayoutEffect, useMemo, useRef } from 'react';
import introspectionQueryResultData from 'src/graphql/__generated__/introspectionResult';
import { useLocale } from './locale';
import { WebSocketLink } from '../ws/WebsocketLink';
import { useAuthContext } from './Auth';
import { ClientOptions } from 'graphql-ws';

/** Apollo provider conditional on region context */
export const ApolloProvider: FC<{ children: ReactNode }> = (props) => {
  const { region, locale } = useLocale();
  const { authClient } = useAuthContext();
  const oktaRef = useRef(authClient);

  /** Store okta client in a ref so that auth link can access okta without re-instantiating the client */
  useLayoutEffect(() => {
    oktaRef.current = authClient;
  }, [authClient]);

  /* Create new link whenever region/locale changes.
   * NOTE: This re-instantiates the client (destroying cache)
   */
  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        headers: {
          'x-ui-region': region,
          'x-ui-locale': locale,
        },
      }),
    [region, locale],
  );

  /* Create new link whenever region/locale changes.
   * NOTE: This re-instantiates the client (destroying cache)
   */
  const wsLink = useMemo(
    () =>
      new WebSocketLink({
        url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT,
        retryAttempts: 20,
        isFatalConnectionProblem: () => false,
        connectionParams: async () => ({
          headers: {
            'x-ui-region': region,
            'x-ui-locale': locale,
            'okta-id-token': (await oktaRef.current.tokenManager.get('idToken')).value,
          },
        }),
      } as ClientOptions),
    [region, locale],
  );

  /** Get latest token from auth ref
   * NOTE: This is only created once
   */
  const authLink = useMemo(
    () =>
      setContext(async (request, previousContext) => {
        if (!oktaRef.current) {
          return previousContext;
        }

        const { value: token } = await oktaRef.current.tokenManager.get('idToken');
        return {
          ...previousContext,
          headers: {
            ...previousContext.headers,
            'okta-id-token': token,
          },
        };
      }),
    [],
  );

  const link = useMemo(
    () =>
      split(
        ({ query }) =>
          query.definitions.some(
            (d) => d.kind === 'OperationDefinition' && d.operation === 'subscription',
          ),
        wsLink,
        from([authLink, httpLink]),
      ),
    [httpLink, wsLink, authLink],
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link,
        cache: new InMemoryCache({
          fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
          }),
        }),
      }),
    [link],
  );

  return <ApolloClientProvider {...props} client={client} />;
};
