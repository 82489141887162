import styled from 'styled-components';

type ButtonProps = { kind?: 'primary' | 'secondary' };

export const Button = styled.button.attrs<ButtonProps, ButtonProps>((props) => ({
  'data-kind': props.kind || 'primary',
  disabled: props.disabled || false,
  ...props,
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.scale(1)};
  border: none;

  &[data-kind='primary'] {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.dark['0']};
    color: ${({ theme }) => theme.color.light['0']};
    padding: ${(p) => p.theme.scale(0)} ${(p) => p.theme.scale(1)};

    &:hover:not(:active) {
      background: ${({ theme }) => theme.color.dark['+4']};
    }
  }

  &[data-kind='secondary'] {
    border-radius: 30px;
    background: ${({ theme }) => theme.color.grey['-6']};
    color: ${({ theme }) => theme.color.light['0']};
    padding: ${(p) => p.theme.scale(-2)} ${(p) => p.theme.scale(1)};

    &:hover:not(:active) {
      background: ${({ theme }) => theme.color.dark['+4']};
    }
  }

  &[disabled],
  &[disabled]:hover:not(:active) {
    background: ${({ theme }) => theme.color.grey['+6']};
    cursor: not-allowed;
  }
`;
