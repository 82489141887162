import { ApolloLink, Observable } from 'apollo-boost';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';

// See example here -https://github.com/enisdenjo/graphql-ws
// Apollo link-ws has problems:
export class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              sink.error(err);
            } else if (err instanceof CloseEvent) {
              sink.error(
                new Error(
                  `Socket closed with event ${err.code} ${
                    err.reason
                      ? `: ${err.reason}` // reason will be available on clean closes
                      : ''
                  }`,
                ),
              );
            } else {
              sink.error(new Error(err.map(({ message }) => message).join(', ')));
            }
          },
        },
      );
    });
  }
}
