import styled from 'styled-components';
import { PageTitle } from 'src/components';

export const Page = styled.div`
  background: ${({ theme }) => theme.color.light['-4']};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.scale(0)};
  max-width: 100%;
  width: 600px;
  margin-bottom: 30%;
`;

export const Title = styled(PageTitle)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.scale(2)};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
