import styled from 'styled-components';
import { Button } from 'src/components';
import { ModalBody } from '../ModalBody';
import { Modal } from '../Modal';

export const ErrorModal = styled(Modal)`
  max-height: 400px;
`;

export const CenteredModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 40px 40px 40px;
`;

export const ErrorModalHeading = styled.h2`
  font-size: ${({ theme }) => theme.scale(1.5)};
  margin: 0;
  line-height: 33px;
`;

export const ErrorModalButton = styled(Button)`
  &[data-kind='secondary'] {
    background-color: ${({ theme }) => theme.color.orange['-6']};
    font-size: ${({ theme }) => theme.scale(0)};
    letter-spacing: 1px;
    padding: 5px 30px;
    margin-top: 5px;
    min-width: 110px;

    &:hover:not(:active) {
      background-color: ${({ theme }) => theme.color.orange['-3']};
    }
  }
`;
