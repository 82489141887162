import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, {
  ComponentProps,
  lazy,
  Suspense,
  FC,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Spinner, PageSpinnerContainer } from 'src/components';
import { InternetConnectivityErrorModal } from 'src/components/modal/error-modal/InternetConnectivityErrorModal';
import { Header, Page, Navigation, Content } from 'src/shell';
import {
  LocaleProvider,
  useLocale,
  StoreProvider,
  ApolloProvider,
  NewOrdersProvider,
  AuthProvider,
} from 'src/context';
import { ThemeProvider } from './shell/Theme';
import { useInternetConnectivityStatus } from './useInternetConnectivityStatus';
import { Heartbeat } from './components/Heartbeat';

const ConfigureLocalePage = lazy(() => import('./pages/configure/configure-locale'));
const HelpPage = lazy(() => import('./pages/help'));
const CompletedOrdersPage = lazy(() => import('./pages/completed-orders'));
const NewOrdersPage = lazy(() => import('./pages/new-orders'));

export const InternetConnectivityWatcher: FC = () => {
  const isOnline = useInternetConnectivityStatus();
  const [isConnectivityErrorModalVisible, setIsConnectivityErrorModalVisible] = useState(!isOnline);
  useEffect(() => {
    setIsConnectivityErrorModalVisible(!isOnline);
  }, [isOnline]);

  return (
    <InternetConnectivityErrorModal
      isModalOpen={isConnectivityErrorModalVisible}
      onDismiss={() => setIsConnectivityErrorModalVisible(false)}
    />
  );
};

const App: FC = () => {
  const flags = useFlags();

  const content = useMemo(() => {
    if (Object.keys(flags).length === 0) {
      return (
        <PageSpinnerContainer>
          <Spinner />
        </PageSpinnerContainer>
      );
    }
    return <AppRoutes />;
  }, [flags]);

  return (
    <ThemeProvider>
      <LocaleProvider>
        <BrowserRouter>{content}</BrowserRouter>
        <InternetConnectivityWatcher />
      </LocaleProvider>
    </ThemeProvider>
  );
};

/**
 * Guard to ensure region has been selected before rendering app content.
 *
 * Redirects to region + locale selector if region is absent.
 */
const RegionGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { region } = useLocale();

  if (!region) {
    return (
      <Suspense fallback={null}>
        <Route path="/" component={ConfigureLocalePage} />
      </Suspense>
    );
  }

  return <>{children}</>;
};

export const Shell: FC<ComponentProps<typeof Page>> = ({ children }) => (
  <>
    <ToastContainer />
    <Header />
    <Page>
      <Navigation />
      <Content>{children}</Content>
    </Page>
  </>
);

const AppRoutes = () => (
  <Switch>
    <AuthProvider>
      <RegionGuard>
        <ApolloProvider>
          <StoreProvider>
            <Heartbeat>
              <NewOrdersProvider>
                <Shell>
                  <Suspense fallback={null}>
                    <Switch>
                      <Route path="/help" component={HelpPage} />
                      <Route path="/completed" component={CompletedOrdersPage} />
                      <Route path="/new" component={NewOrdersPage} />
                      <Redirect to="/new" />
                    </Switch>
                  </Suspense>
                </Shell>
              </NewOrdersProvider>
            </Heartbeat>
          </StoreProvider>
        </ApolloProvider>
      </RegionGuard>
    </AuthProvider>
  </Switch>
);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_RBI_LAUNCH_DARKLY_KEY,
  options: {
    streaming: true,
  },
})(App);
