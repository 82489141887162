import React, { FC, ComponentProps, lazy, Suspense, useMemo } from 'react';
import { useBrand } from 'src/context';
import styled from 'styled-components';

const logos = {
  bk: lazy(() => import('./HeaderLogo.bk')),
  plk: lazy(() => import('./HeaderLogo.plk')),
  th: lazy(() => import('./HeaderLogo.th')),
} as const;

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: ${(p) => p.theme.scale(3.5)};
  max-height: ${(p) => p.theme.scale(3.5)};
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.color.light['-6']};
`;

export const Version = styled.div`
  align-self: flex-end;
  flex-basis: 100%;
  text-align: right;
  font-size: 12px;
  margin-right: 8px;
`;

export const Header: FC<ComponentProps<typeof Container>> = (props) => {
  const brand = useBrand();

  const Logo = useMemo(() => logos[brand], [brand]);

  return (
    <Container {...props}>
      <Suspense fallback={null}>
        <Logo style={{ width: '100px', padding: '12px 18px' }} />
      </Suspense>
      <Version>{`ver: ${process.env.REACT_APP_VERSION}`}</Version>
    </Container>
  );
};
