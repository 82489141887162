import React, { FC, useEffect } from 'react';
import { Portal } from 'react-portal';
import FocusLock from 'react-focus-lock';
import { rgba } from 'polished';
import styled from 'styled-components';
import { CloseFunctionalityWrapper } from '../CloseFunctionalityWrapper';

const ModalContainer = styled.div<{ size?: 'small' | 'large' }>`
  width: ${(props) => (props.size === 'small' ? '300px' : '600px')};
  min-height: ${(props) => (props.size === 'small' ? '300px' : '350px')};
  max-height: ${(props) => (props.size === 'small' ? '300px' : '80vh')};
  background-color: ${({ theme }) => theme.color.light['0']};
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: 0.6rem;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.color.dark['0'], 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IModalProps {
  isModalOpen: boolean;
  size?: 'small' | 'large';
  onDismiss: () => void;
}

export const Modal: FC<IModalProps> = ({
  isModalOpen = false,
  size = 'large',
  children,
  onDismiss,
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
  }, [isModalOpen]);

  return isModalOpen ? (
    <Portal>
      <ModalOverlay data-test-id="modal-overlay">
        <CloseFunctionalityWrapper dismissCallback={onDismiss}>
          <FocusLock returnFocus>
            <ModalContainer aria-modal="true" role="dialog" size={size} {...props}>
              {children}
            </ModalContainer>
          </FocusLock>
        </CloseFunctionalityWrapper>
      </ModalOverlay>
    </Portal>
  ) : null;
};
