import React, { FC, ComponentProps, useMemo } from 'react';
import { useLocale } from 'src/context';
import { OrderFragmentFragment } from 'src/graphql';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.scale(-1)} ${(p) => p.theme.scale(1)};
  display: grid;
  grid-template-columns: 3fr 2fr 4fr 5fr 1fr 2fr;
  column-gap: ${(p) => p.theme.scale(-1)};
  color: ${({ theme }) => theme.color.light['0']};

  /* Fallback to make sure white text is visible. */
  background: ${({ theme }) => theme.color.dark['+6']};

  &[data-type='DRIVE_THRU'] {
    background: ${({ theme }) => theme.color.green['0']};
  }

  &[data-type='DELIVERY'] {
    background: ${({ theme }) => theme.color.blue['0']};
  }

  &[data-type='TAKEOUT'] {
    background: ${({ theme }) => theme.color.orange['0']};
  }

  &[data-type='CURBSIDE'] {
    background: ${({ theme }) => theme.color.purple['0']};
  }

  &[data-type='CATERING_PICKUP'] {
    background: ${({ theme }) => theme.color.red['-6']};
  }

  &[data-type='CATERING_DELIVERY'] {
    background: ${({ theme }) => theme.color.brown['-6']};
  }

  &[data-type='EAT_IN'] {
    background: ${({ theme }) => theme.color.indigo['0']};
  }
`;

const OrderItem = styled.div``;

const ItemTitle = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: ${(p) => p.theme.scale(0)};
  line-height: 1.3em;

  /* Workaround for payment status indicator */
  &[data-empty='true'] {
    min-height: 1.3em;
  }
`;

const ItemDescription = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: ${(p) => p.theme.scale(1)};

  /* Workaround for payment status indicator (may cause overflow/wrapping) */
  &[data-payment='true'] {
    width: 3em;
    text-align: right;
  }
`;

const orderMethods: Record<OrderFragmentFragment['serviceMode'], string> = {
  CATERING_PICKUP: 'Catering Pick up',
  CATERING_DELIVERY: 'Catering Delivery',
  CURBSIDE: 'Curbside',
  DELIVERY: 'Delivery',
  DRIVE_THRU: 'Drive thru',
  EAT_IN: 'Eat in',
  TAKEOUT: 'Takeout',
};
type OrderSummaryProps = { order: OrderFragmentFragment } & ComponentProps<typeof Container>;

export const OrderSummary: FC<OrderSummaryProps> = ({ order, ...props }) => {
  const { formatDate, formatMessage, formatNumber, formatTime, currency } = useLocale();

  const items = useMemo<{ title: string; value: string }[]>(
    () => [
      {
        title: formatDate(new Date(order.timestamp), {
          month: 'short',
          day: 'numeric',
        }),
        value: formatTime(new Date(order.timestamp)),
      },
      {
        title: formatMessage({ id: 'order-summary.heading.order' }),
        value: `${order.ticketNumber}`,
      },
      {
        title: formatMessage({ id: 'order-summary.heading.method' }),
        value: orderMethods[order.serviceMode],
      },
      {
        title: formatMessage({ id: 'order-summary.heading.guest' }),
        value: order.customer.name,
      },
      {
        title: '',
        value: order.paymentStatus === 'PAYMENT_REQUIRED' ? 'CASH' : '',
        payment: true, // Some indicator to ensure that this row is presented differently to the others
      },
      {
        title: formatMessage({ id: 'order-summary.heading.total' }),
        value: formatNumber(order.total / 100, { style: 'currency', currency }),
      },
    ],
    [order, formatDate, formatMessage, formatNumber, formatTime, currency],
  );

  return (
    <Container data-type={order.serviceMode} {...props}>
      {items.map(({ title, value }, index) => (
        <OrderItem key={`${title}${value}`}>
          <ItemTitle data-empty={title === ''}>{title}</ItemTitle>
          <ItemDescription data-payment={title === ''}>{value}</ItemDescription>
        </OrderItem>
      ))}
    </Container>
  );
};
