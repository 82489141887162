import React, {
  Dispatch,
  SetStateAction,
  createContext,
  FC,
  ReactNode,
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
} from 'react';
import LogRocket from 'logrocket';
import ConfigureStore from 'src/pages/configure/configure-store';
import { datadogRum } from '@datadog/browser-rum';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocale } from './locale';

export const StoreContext = createContext<GuardedStoreContext>({} as GuardedStoreContext);
export const useStoreContext = () => useContext<GuardedStoreContext>(StoreContext);

type GuardedStoreContext = {
  storeId: string;
  setStoreId: Dispatch<SetStateAction<string | undefined>>;
};

type StoreProviderProps = {
  children: ReactNode;
  /** Force region (for storybook/testing only!). */
  forceStoreId?: string;
};

/** For use outside of store guard */
export type UnguardedStoreContext = Omit<GuardedStoreContext, 'storeId'> & { storeId?: string };

export const StoreProvider: FC<StoreProviderProps> = ({ children, forceStoreId }) => {
  const storeIdInitialValue = forceStoreId || localStorage.getItem('selectedStoreId') || undefined;
  const [storeId, setStoreId] = useState<UnguardedStoreContext['storeId']>(storeIdInitialValue);
  const { region, locale } = useLocale();

  const ldClient = useLDClient();

  useEffect(() => {
    if (storeId !== undefined) {
      const user = {
        anonymous: false,
        key: storeId,
        custom: {
          brand: process.env.REACT_APP_BRAND,
          storeId: storeId!,
          region: region ?? '',
        },
      };
      ldClient?.identify(user);
    }
  }, [ldClient, region, storeId]);

  useLayoutEffect(() => {
    if (storeId === undefined) {
      localStorage.removeItem('selectedStoreId');
      return;
    }
    localStorage.setItem('selectedStoreId', storeId);
    datadogRum.addRumGlobalContext('context', {
      store: storeId,
      region,
      locale,
    });
    LogRocket.identify(storeId);
  }, [locale, region, storeId]);

  return (
    <StoreContext.Provider value={{ storeId: storeId as any, setStoreId }}>
      {!storeId ? (
        <>
          <ConfigureStore />
        </>
      ) : (
        <>{children}</>
      )}
    </StoreContext.Provider>
  );
};
