import React, { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { GetNewOrdersQuery } from 'src/graphql';
import notificationSound from './assets/new-order-notification.mp3';

const Text = styled.div`
  font-size: 1.3rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.color.light['0']};
`;

const Content = ({ ordersLength }: { ordersLength: number }) => {
  return <Text>{`You have ${ordersLength} new orders`}</Text>;
};

/** Subscribes to new orders and triggers notification on update */
export const useNewOrderListener = (data: GetNewOrdersQuery | undefined) => {
  const audio = useRef(new Audio(notificationSound));
  const ref = useRef({
    audioInterval: 0,
    vibrationInterval: 0,
    audioAndVibrationEnabled: false,
    toastId: '' as string | number,
    lastOrders: [] as GetNewOrdersQuery['newOrders']['edges'],
    lastAcknowledgedOrders: [] as GetNewOrdersQuery['newOrders']['edges'],
  });

  const newOrderCount = useMemo(() => {
    // No orders
    if (!data?.newOrders || data.newOrders.edges.length === 0) {
      return 0;
    }

    // All new orders
    if (ref.current.lastAcknowledgedOrders.length === 0) {
      return data?.newOrders.edges.length;
    }

    const previousIds = ref.current.lastAcknowledgedOrders.reduce<Record<string, true | undefined>>(
      (p, edge) => ({
        ...p,
        [edge.node.id]: true,
      }),
      {},
    );

    // Count orders that were not in the prior response
    return data.newOrders.edges.reduce(
      (count, edge) => (previousIds[edge.node.id] ? count : count + 1),
      0,
    );
  }, [data, ref]);

  /** Expose latest orders via ref */
  useEffect(() => {
    ref.current.lastOrders = data?.newOrders.edges ?? [];
  }, [data]);

  /** Update active toast on order length change. */
  useEffect(() => {
    // No active toast
    if (!ref.current.toastId || !newOrderCount) {
      return;
    }

    const Toast = () => <Content ordersLength={newOrderCount} />;
    toast.update(ref.current.toastId, {
      render: Toast,
    });
  }, [data, newOrderCount, ref.current.toastId]);

  /**
   * Show the New Order toast when orders are updated.
   *
   * Note:
   * User needs to interact with the document before we can call play() or vibrate(),
   * we'll check if the play promise resolved and only then register the intervals for
   * audio and vibration.
   */
  useEffect(() => {
    // Sets play() and vibrate() intervals.
    const enableAudioAndVibration = async () => {
      const audioDidPlay = await audio?.current
        ?.play()
        .then(() => true)
        .catch((err) => false);

      if (!audioDidPlay) {
        // eslint-disable-next-line no-console
        console.warn(
          'User did not interact with document yet. Audio and vibration cannot be enabled.',
        );
        return;
      }

      if (navigator?.vibrate) {
        ref.current.vibrationInterval = window.setInterval(() => navigator.vibrate(100), 500);
      }

      ref.current.audioInterval = window.setInterval(() => audio.current.play(), 500);
      ref.current.audioAndVibrationEnabled = true;
    };

    // Shows the New Orders Modal.
    const showNewOrdersToast = async () => {
      if (ref.current.toastId) {
        // If toast already open but audio never played:
        !ref.current.audioAndVibrationEnabled && (await enableAudioAndVibration());
        return;
      }

      if (newOrderCount === 0) {
        return;
      }

      ref.current.toastId = toast.success(<Content ordersLength={newOrderCount} />, {
        autoClose: false,
        onClose: () => {
          window.clearInterval(ref.current.audioInterval);
          if (ref.current.vibrationInterval) {
            window.clearInterval(ref.current.vibrationInterval);
          }
          ref.current.toastId = '';
          ref.current.audioAndVibrationEnabled = false;
          ref.current.lastAcknowledgedOrders = ref.current.lastOrders;
        },
      });

      enableAudioAndVibration();
    };

    // eslint-disable-next-line no-console
    showNewOrdersToast().catch(console.error);
  }, [
    data,
    newOrderCount,
    ref.current.toastId,
    ref.current.audioInterval,
    ref.current.vibrationInterval,
  ]);
};
