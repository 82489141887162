import React, { FC, ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import { ClipboardIcon, QuestionMarkCircleIcon, CheckCircleIcon } from 'src/components';
import { useLocale } from 'src/context';
import styled from 'styled-components';

const NavigationContainer = styled.nav`
  --item-height: 80px;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
  width: ${(p) => p.theme.scale(4)};
  border-right: solid 1px ${({ theme }) => theme.color.light['-6']};
`;

const NavItem = styled((props) => <NavLink {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--item-height);

  text-decoration: none;
  font-size: ${(p) => p.theme.scale(-1)};
  border-top: solid 1px ${({ theme }) => theme.color.light['-6']};
  color: ${({ theme }) => theme.color.grey['+6']};

  &.active {
    color: ${({ theme }) => theme.color.dark['0']};
    font-weight: bold;
  }

  &:hover:not(:active) {
    color: ${({ theme }) => theme.color.dark['+6']};
  }

  :active {
    color: ${({ theme }) => theme.color.dark['0']};
  }

  svg {
    font-size: ${(p) => p.theme.scale(1)};
    margin-bottom: ${(p) => p.theme.scale(-3)};
  }
`;

const NavMarker = styled.div`
  position: absolute;
  right: 0;
  width: 4px;
  height: 25px;
  transform: translateY(50%);
  background: ${({ theme }) => theme.color.dark['0']};
  transition: bottom ease 300ms;

  .active:nth-child(1) ~ & {
    bottom: calc(var(--item-height) * 2 + var(--item-height) / 2);
  }

  .active:nth-child(2) ~ & {
    bottom: calc(var(--item-height) * 1 + var(--item-height) / 2);
  }

  .active:nth-child(3) ~ & {
    bottom: calc(var(--item-height) * 0 + var(--item-height) / 2);
  }
`;

export const Navigation: FC<ComponentProps<typeof NavigationContainer>> = (props) => {
  const { formatMessage } = useLocale();

  return (
    <NavigationContainer {...props}>
      <NavItem to="/help">
        <QuestionMarkCircleIcon />
        {formatMessage({ id: 'nav.help' })}
      </NavItem>
      <NavItem to="/completed">
        <CheckCircleIcon />
        {formatMessage({ id: 'nav.done' })}
      </NavItem>
      <NavItem to="/new">
        <ClipboardIcon />
        {formatMessage({ id: 'nav.new' })}
      </NavItem>
      <NavMarker />
    </NavigationContainer>
  );
};
