import React, { createContext, FC, useContext, useEffect, useRef } from 'react';
import { useGetNewOrdersQuery, useNewOrdersSubscription } from 'src/graphql';
import { useNewOrderListener } from 'src/useNewOrderListener';
import { useInternetConnectivityStatus } from 'src/useInternetConnectivityStatus';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { useStoreContext } from './Store';
import { useOrderAcknowledgment } from './useOrderAcknowledgment';

type NewOrdersContext = ReturnType<typeof useGetNewOrdersQuery>;

export const NewOrdersContext = createContext<NewOrdersContext>(null as any);

export const useNewOrders = () => useContext(NewOrdersContext);

export const NewOrdersProvider: FC = ({ children }) => {
  const { forcePollingForNewOrders, pollingIntervalMs, enableDisplayAdditionalFees } = useFlags();
  const { storeId } = useStoreContext();
  const getOrdersQuery = useGetNewOrdersQuery({
    variables: {
      first: 10,
      storeId,
      displayDeliveryFees: enableDisplayAdditionalFees,
    },
    skip: !storeId,
    notifyOnNetworkStatusChange: true,
  });
  useOrderAcknowledgment(getOrdersQuery.data);
  const { startPolling, stopPolling, refetch, error: queryError } = getOrdersQuery;
  const { error } = useNewOrdersSubscription({
    variables: {
      storeId,
      displayDeliveryFees: enableDisplayAdditionalFees,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data?.newOrders?.length) {
        return;
      }

      getOrdersQuery.updateQuery((state) => ({
        newOrders: {
          edges: [
            ...(state?.newOrders?.edges || []),
            ...(subscriptionData.data?.newOrders ?? []).map(
              (node) => ({ node, cursor: null, __typename: 'OrderEdge' } as const),
            ),
          ],
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            __typename: 'PageInfo',
            ...(state?.newOrders?.pageInfo as any),
          },
          __typename: 'OrderConnection',
        },
        __typename: 'Query',
      }));
    },
  });

  useEffect(() => {
    if (forcePollingForNewOrders) {
      startPolling(pollingIntervalMs);
    }
    return () => stopPolling();
  }, [forcePollingForNewOrders, pollingIntervalMs, startPolling, stopPolling]);

  useEffect(() => {
    if (error && !queryError) {
      datadogRum.addError(error);

      // It looks like startPolling doesn't trigger right away:
      // https://github.com/apollographql/apollo-client/issues/4439
      refetch();
      startPolling(pollingIntervalMs);
    }

    return () => stopPolling();
  }, [error, startPolling, stopPolling, refetch, queryError, pollingIntervalMs]);

  const isOnline = useInternetConnectivityStatus();

  /**
   * detects when internet reconnects.
   * initially, useInternetConnectivityStatus returns true
   * in order to avoid firing off two requests, we only consider connectivity
   * on state updates.
   */
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (isOnline) {
      refetch();
    }
  }, [isOnline, refetch]);

  useNewOrderListener(getOrdersQuery.data);

  return <NewOrdersContext.Provider value={getOrdersQuery}>{children}</NewOrdersContext.Provider>;
};
