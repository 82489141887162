/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactDOM from 'react-dom';
import { init as initLogRocket } from 'src/utils/logrocket';
import { initDataDogRUM } from 'src/utils/datadog';
import App from './App';
import { register } from './sw';
import { ErrorGuard } from './ErrorGuard';

initDataDogRUM();
initLogRocket();

ReactDOM.render(
  <ErrorGuard>
    <App />
  </ErrorGuard>,
  document.getElementById('root'),
);

register();
