import LogRocket from 'logrocket';

export function init(): void {
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_STAGE === 'dev') {
    return;
  }

  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY, {
    release: process.env.REACT_APP_VERSION,
  });
}
