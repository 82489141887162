import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { createIntl, IntlShape, createIntlCache, MessageDescriptor } from 'react-intl';
import enDictionary from './dictionaries/en.json';
import { Locale, Region, Currency, LocalizedDictionary, RegionToCurrencyMap } from './types';

export * from './types';

const LOCALE_SEPARATOR = '-';

interface ILocaleContext extends Omit<IntlShape, 'formatMessage'> {
  /** Language and grammar (user configurable). */
  locale: Locale;
  /** Change language/grammar. */
  setLocale: (args: Locale) => void;
  /** Physical region where user is located. */
  region?: Region;
  /** Change region */
  setRegion: (args: Region) => void;
  /** Derived state based on region. */
  currency?: Currency;
  /** Type safe message retrieval */
  formatMessage: PatchFunction<IntlShape['formatMessage']>;
}

type LocaleProviderProps = {
  /** Force region (for storybook/testing only!). */
  forceRegion?: Region;
};

export const LocaleContext = createContext<ILocaleContext>({} as ILocaleContext);

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider: FC<LocaleProviderProps> = ({ children, forceRegion }) => {
  const cache = useRef(createIntlCache());
  const selectedRegion = (localStorage.getItem('selectedRegion') ??
    undefined) as ILocaleContext['region'];

  const [region, setRegion] = useState(forceRegion || selectedRegion);
  const currency = useMemo(() => (region ? RegionToCurrencyMap[region] : undefined), [region]);

  const hasSetLocale = useRef(true);
  const [{ messages, locale }, setMessagesAndLocale] = useState<{
    messages: Record<string, string>;
    locale: Locale;
  }>({ messages: enDictionary, locale: 'en-GB' });

  // Set locale and messages at the same time
  const setLocale = useCallback(async (newLocale: Locale) => {
    const [baseLanguage] = newLocale?.split(LOCALE_SEPARATOR);

    try {
      const [baseDictionary, localeDictionary] = await Promise.all([
        import(`./dictionaries/${baseLanguage}.json`),
        import(`./dictionaries/${newLocale?.replace('-', '_')}.json`).catch((err) => {
          // eslint-disable-next-line
          console.error(err);
          return {};
        }),
      ]);
      hasSetLocale.current = false;
      setMessagesAndLocale({
        locale: newLocale,
        messages: {
          ...enDictionary,
          ...baseDictionary,
          ...localeDictionary,
        },
      });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      setMessagesAndLocale({
        locale: 'en-GB',
        messages: {
          ...enDictionary,
        },
      });
    }
  }, []);

  // Load non-default locale on first render
  useLayoutEffect(() => {
    const localeFromStorage = (localStorage.getItem('selectedLocale') as Locale) || null;

    if (localeFromStorage === null) {
      return;
    }

    setLocale(localeFromStorage);
  }, [setLocale]);

  useLayoutEffect(() => {
    // Ignore default locale (not user preference)
    if (hasSetLocale.current) {
      return;
    }

    window.localStorage.setItem('selectedLocale', locale);
  }, [locale]);

  useLayoutEffect(() => {
    if (!region) {
      return;
    }

    window.localStorage.setItem('selectedRegion', region);
  }, [region]);

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale,
          messages,
        },
        cache.current,
      ),
    [locale, messages],
  );

  const state = useMemo(
    () => ({
      ...intl,
      locale,
      setLocale,
      setRegion,
      region,
      currency,
    }),
    [intl, locale, setLocale, region, currency, setRegion],
  );

  return <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>;
};

/** Override formatMessage functions. */
type PatchFunction<F> = F extends (descriptor: MessageDescriptor, ...args: infer P) => infer R
  ? (descriptor: { id: keyof LocalizedDictionary }, ...args: P) => string
  : F;
