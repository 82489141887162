import React, { FC, ReactNode } from 'react';
import { mix, modularScale } from 'polished';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';
import 'src/assets/th/fonts.css';

const dark = '#222';
const light = '#fff';
const blue = '#0075d9';
const indigo = '#3a539b';
const green = '#1c8837';
const orange = '#ff8533';
const red = '#ec1d24';
const purple = '#B10DC9';
const brown = '#6f3c2f';

const scaleRoot = 18;

const theme = {
  scale: (...args: Parameters<typeof modularScale>) =>
    modularScale(args[0], args[1] || `${scaleRoot}px`, args[2] || 1.5),
  color: {
    dark: {
      '0': dark,
      '+1': mix(0.02, light, dark),
      '+2': mix(0.04, light, dark),
      '+3': mix(0.06, light, dark),
      '+4': mix(0.08, light, dark),
      '+5': mix(0.1, light, dark),
      '+6': mix(0.12, light, dark),
    },
    light: {
      '0': light,
      '-1': mix(0.02, dark, light),
      '-2': mix(0.04, dark, light),
      '-3': mix(0.06, dark, light),
      '-4': mix(0.08, dark, light),
      '-5': mix(0.1, dark, light),
      '-6': mix(0.12, dark, light),
    },
    grey: {
      '-6': mix(0.38, light, dark),
      '-5': mix(0.4, light, dark),
      '-4': mix(0.42, light, dark),
      '-3': mix(0.44, light, dark),
      '-2': mix(0.46, light, dark),
      '-1': mix(0.48, light, dark),
      '0': mix(0.5, light, dark),
      '+1': mix(0.52, light, dark),
      '+2': mix(0.54, light, dark),
      '+3': mix(0.56, light, dark),
      '+4': mix(0.58, light, dark),
      '+5': mix(0.6, light, dark),
      '+6': mix(0.62, light, dark),
    },
    blue: {
      '-6': mix(0.76, blue, dark),
      '-5': mix(0.8, blue, dark),
      '-4': mix(0.84, blue, dark),
      '-3': mix(0.88, blue, dark),
      '-2': mix(0.92, blue, dark),
      '-1': mix(0.96, blue, dark),
      '0': blue,
      '+1': mix(0.96, blue, light),
      '+2': mix(0.92, blue, light),
      '+3': mix(0.88, blue, light),
      '+4': mix(0.84, blue, light),
      '+5': mix(0.8, blue, light),
      '+6': mix(0.76, blue, light),
    },
    indigo: {
      '-6': mix(0.76, indigo, dark),
      '-5': mix(0.8, indigo, dark),
      '-4': mix(0.84, indigo, dark),
      '-3': mix(0.88, indigo, dark),
      '-2': mix(0.92, indigo, dark),
      '-1': mix(0.96, indigo, dark),
      '0': indigo,
      '+1': mix(0.96, indigo, light),
      '+2': mix(0.92, indigo, light),
      '+3': mix(0.88, indigo, light),
      '+4': mix(0.84, indigo, light),
      '+5': mix(0.8, indigo, light),
      '+6': mix(0.76, indigo, light),
    },
    green: {
      '-6': mix(0.76, green, dark),
      '-5': mix(0.8, green, dark),
      '-4': mix(0.84, green, dark),
      '-3': mix(0.88, green, dark),
      '-2': mix(0.92, green, dark),
      '-1': mix(0.96, green, dark),
      '0': green,
      '+1': mix(0.96, green, light),
      '+2': mix(0.92, green, light),
      '+3': mix(0.88, green, light),
      '+4': mix(0.84, green, light),
      '+5': mix(0.8, green, light),
      '+6': mix(0.76, green, light),
    },
    orange: {
      '-6': mix(0.76, orange, dark),
      '-5': mix(0.8, orange, dark),
      '-4': mix(0.84, orange, dark),
      '-3': mix(0.88, orange, dark),
      '-2': mix(0.92, orange, dark),
      '-1': mix(0.96, orange, dark),
      '0': orange,
      '+1': mix(0.96, orange, light),
      '+2': mix(0.92, orange, light),
      '+3': mix(0.88, orange, light),
      '+4': mix(0.84, orange, light),
      '+5': mix(0.8, orange, light),
      '+6': mix(0.76, orange, light),
    },
    red: {
      '-6': mix(0.76, red, dark),
      '-5': mix(0.8, red, dark),
      '-4': mix(0.84, red, dark),
      '-3': mix(0.88, red, dark),
      '-2': mix(0.92, red, dark),
      '-1': mix(0.96, red, dark),
      '0': red,
      '+1': mix(0.96, red, light),
      '+2': mix(0.92, red, light),
      '+3': mix(0.88, red, light),
      '+4': mix(0.84, red, light),
      '+5': mix(0.8, red, light),
      '+6': mix(0.76, red, light),
    },
    purple: {
      '-6': mix(0.76, purple, dark),
      '-5': mix(0.8, purple, dark),
      '-4': mix(0.84, purple, dark),
      '-3': mix(0.88, purple, dark),
      '-2': mix(0.92, purple, dark),
      '-1': mix(0.96, purple, dark),
      '0': purple,
      '+1': mix(0.96, purple, light),
      '+2': mix(0.92, purple, light),
      '+3': mix(0.88, purple, light),
      '+4': mix(0.84, purple, light),
      '+5': mix(0.8, purple, light),
      '+6': mix(0.76, purple, light),
    },
    brown: {
      '-6': mix(0.76, brown, dark),
      '-5': mix(0.8, brown, dark),
      '-4': mix(0.84, brown, dark),
      '-3': mix(0.88, brown, dark),
      '-2': mix(0.92, brown, dark),
      '-1': mix(0.96, brown, dark),
      '0': brown,
      '+1': mix(0.96, brown, light),
      '+2': mix(0.92, brown, light),
      '+3': mix(0.88, brown, light),
      '+4': mix(0.84, brown, light),
      '+5': mix(0.8, brown, light),
      '+6': mix(0.76, brown, light),
    },
  },
};

const GlobalStyle = createGlobalStyle`
  html {
    font-family: "sofia_pro", sans-serif;
  }

  h1, h2, h3 {
    font-family: "sofia_pro", sans-serif;
  }
`;

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <StyledThemeProvider theme={theme}>
      {children}
      <GlobalStyle />
    </StyledThemeProvider>
  </>
);

export default ThemeProvider;
