import styled from 'styled-components';

type InputProps = { invalid?: boolean };

export const Input = styled.input.attrs<InputProps, InputProps>((props) => ({
  'aria-invalid': Boolean(props.invalid),
  ...props,
}))`
  color: ${(p) => p.theme.color.dark['0']};
  font-size: ${(p) => p.theme.scale(0)};
  border-radius: 2px;
  border: solid 1px ${(p) => p.theme.color.light['-6']};
  padding: ${(p) => p.theme.scale(1)} ${(p) => p.theme.scale(-2)};
  margin-bottom: ${(p) => p.theme.scale(1)};

  &::placeholder {
    color: ${(p) => p.theme.color.grey['0']};
  }

  &[data-invalid='true'] {
    border-color: ${(p) => p.theme.color.red['0']};
  }
`;
