import { useApolloClient } from '@apollo/react-hooks';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useRef } from 'react';
import {
  GetNewOrdersQuery,
  AcknowledgeOrdersDocument,
  AcknowledgeOrdersMutation,
} from 'src/graphql';

/** Subscribes to new orders and triggers notification on update */
export const useOrderAcknowledgment = (data: GetNewOrdersQuery | undefined) => {
  const { mutate } = useApolloClient();
  const acknowledged = useRef<Record<string, boolean>>({});

  useEffect(() => {
    const ids = data?.newOrders.edges
      .map(({ node }) => node.id)
      .filter((id) => !acknowledged.current[id]);

    if (!ids || ids.length === 0) {
      return;
    }

    mutate<AcknowledgeOrdersMutation>({ mutation: AcknowledgeOrdersDocument, variables: { ids } })
      .then(() =>
        ids.forEach((id) => {
          acknowledged.current[id] = true;
        }),
      )
      .catch((e) => datadogRum.addError(e));
  }, [data, mutate]);
};
