import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A cursor for pagination purposes. */
  Cursor: any;
};

export type CompletedOrderDateRangeInput = {
  /** Earliest date of order. */
  readonly from?: Maybe<Scalars['Date']>;
  /** Latest date of order (defaults to now). */
  readonly to?: Maybe<Scalars['Date']>;
};

export type Customer = {
  readonly __typename: 'Customer';
  readonly name: Scalars['String'];
};

/** Delivery status of the order. */
export type DeliveryStatus =
  | 'DRIVER_ASSIGNED'
  | 'DRIVER_AT_CUSTOMER'
  | 'DRIVER_AT_STORE'
  | 'DRIVER_STARTING'
  | 'DRIVER_UNASSIGNED'
  | 'ORDER_ABANDONED'
  | 'ORDER_CANCELLED'
  | 'ORDER_CREATED'
  | 'ORDER_DROPPED_OFF'
  | 'ORDER_ERROR'
  | 'ORDER_PICKED_UP'
  | 'ORDER_TIMEOUT'
  | 'QUOTE_ERROR'
  | 'QUOTE_REQUESTED'
  | 'QUOTE_SUCCESSFUL'
  | 'QUOTE_UNAVAILABLE';

export type Location = {
  readonly __typename: 'Location';
  readonly longitude: Scalars['Float'];
  readonly latitude: Scalars['Float'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly _: Scalars['Boolean'];
  readonly completeOrder: Order;
  readonly acknowledgeOrders: ReadonlyArray<Order>;
  readonly sendHeartbeat: Scalars['Boolean'];
};

export type MutationCompleteOrderArgs = {
  id: Scalars['ID'];
};

export type MutationAcknowledgeOrdersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};

export type MutationSendHeartbeatArgs = {
  storeId: Scalars['ID'];
};

export type NewOrderDateRangeInput = {
  /** Earliest date of order. */
  readonly from?: Maybe<Scalars['Date']>;
  /** Latest date of order (defaults to now). */
  readonly to?: Maybe<Scalars['Date']>;
};

/** A user submitted order. */
export type Order = {
  readonly __typename: 'Order';
  /** Order ID. */
  readonly id: Scalars['ID'];
  readonly ticketNumber: Scalars['String'];
  readonly timestamp: Scalars['Date'];
  readonly customer: Customer;
  readonly serviceMode: ServiceMode;
  readonly paymentStatus: PaymentStatus;
  /** Order subtotal in cents. */
  readonly subtotal: Scalars['Int'];
  /** Order total in cents. */
  readonly total: Scalars['Int'];
  /** Order sales tax in cents. */
  readonly tax: Scalars['Int'];
  /** Items ordered. */
  readonly items: ReadonlyArray<OrderItemSelection>;
  /** Delivery Status */
  readonly deliveryStatus?: Maybe<DeliveryStatus>;
  /** DeliveryFee in cents. */
  readonly deliveryFeeCents?: Maybe<Scalars['Int']>;
  /** Geographical fee in cents. */
  readonly geographicalFeeCents?: Maybe<Scalars['Int']>;
  /** Smallcart fee in cents. */
  readonly smallCartFeeCents?: Maybe<Scalars['Int']>;
  /** Service fee in cents. */
  readonly serviceFeeCents?: Maybe<Scalars['Int']>;
  /** Tip in cents. */
  readonly tipCents?: Maybe<Scalars['Int']>;
};

export type OrderConnection = {
  readonly __typename: 'OrderConnection';
  readonly pageInfo: PageInfo;
  readonly edges: ReadonlyArray<OrderEdge>;
};

export type OrderDateRangeInput = {
  /** Earliest date of order. */
  readonly from: Scalars['Date'];
  /** Latest date of order (defaults to now). */
  readonly to?: Maybe<Scalars['Date']>;
};

export type OrderEdge = {
  readonly __typename: 'OrderEdge';
  readonly cursor: Scalars['Cursor'];
  readonly node: Order;
};

/** An item within the order (i.e. cart item). */
export type OrderItemSelection = {
  readonly __typename: 'OrderItemSelection';
  readonly product: Scalars['String'];
  /** Name of order item. */
  readonly name: Scalars['String'];
  /** Price in cents. */
  readonly price: Scalars['Int'];
  /** Quantity of order items. */
  readonly quantity: Scalars['Int'];
  /** Type of order item selection. */
  readonly type: OrderItemSelectionType;
  /** Item should be visible */
  readonly visible: Scalars['Boolean'];
  /** Modifiers for order items. */
  readonly children: ReadonlyArray<OrderItemSelection>;
  /** lineId */
  readonly lineId: Scalars['String'];
};

/** Type of order item selection. */
export type OrderItemSelectionType =
  | 'Combo'
  | 'ComboSlot'
  | 'Item'
  | 'ItemOption'
  | 'ItemOptionModifier'
  | 'OfferCombo'
  | 'OfferDiscount'
  | 'OfferItem';

/** Status of order. */
export type OrderStatus =
  | 'NEW'
  | 'COMPLETED'
  | 'CASHIER_INPUT_FAILED'
  | 'CASHIER_INPUT_REQUESTED'
  | 'CASHIER_INPUT_SUCCESSFUL';

export type PageInfo = {
  readonly __typename: 'PageInfo';
  readonly endCursor?: Maybe<Scalars['Cursor']>;
  readonly startCursor?: Maybe<Scalars['Cursor']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
};

/** Payment status of the order. */
export type PaymentStatus = 'PAYMENT_SUCCESSFUL' | 'PAYMENT_REQUIRED';

export type ProductOrderModifier = {
  readonly __typename: 'ProductOrderModifier';
  readonly name: Scalars['String'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly _: Scalars['Boolean'];
  /**
   * Get recent orders for a given store.
   * @deprecated Use newOrders query which supports pagination
   */
  readonly orders: ReadonlyArray<Order>;
  /** Get new orders for a given store (default order by oldest first). */
  readonly newOrders: OrderConnection;
  /** Get completed orders for a given store (default order by newest first). */
  readonly completedOrders: OrderConnection;
  readonly store?: Maybe<Store>;
};

export type QueryOrdersArgs = {
  store: Scalars['ID'];
  insertedAt: OrderDateRangeInput;
  status?: Maybe<OrderStatus>;
};

export type QueryNewOrdersArgs = {
  store: Scalars['ID'];
  insertedAt?: Maybe<NewOrderDateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
};

export type QueryCompletedOrdersArgs = {
  store: Scalars['ID'];
  insertedAt?: Maybe<CompletedOrderDateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
};

export type QueryStoreArgs = {
  id: Scalars['ID'];
};

/** Mode of order requested (e.g. delivery, takeout, etc.). */
export type ServiceMode =
  | 'CATERING_PICKUP'
  | 'CATERING_DELIVERY'
  | 'CURBSIDE'
  | 'DELIVERY'
  | 'DRIVE_THRU'
  | 'EAT_IN'
  | 'TAKEOUT';

export type Store = {
  readonly __typename: 'Store';
  readonly id: Scalars['ID'];
  readonly location: Location;
  readonly streetNumber: Scalars['String'];
  readonly city: Scalars['String'];
  readonly stateProvince?: Maybe<Scalars['String']>;
  readonly country: Scalars['String'];
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly _: Scalars['Boolean'];
  /** New orders coming through for store */
  readonly newOrders: ReadonlyArray<Order>;
};

export type SubscriptionNewOrdersArgs = {
  restaurantId: Scalars['ID'];
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
};

export type OrderItemFieldsFragmentFragment = {
  readonly __typename: 'OrderItemSelection';
  readonly product: string;
  readonly name: string;
  readonly price: number;
  readonly quantity: number;
  readonly type: OrderItemSelectionType;
  readonly visible: boolean;
  readonly lineId: string;
};

export type OrderItemFragmentFragment = {
  readonly __typename: 'OrderItemSelection';
  readonly children: ReadonlyArray<
    {
      readonly __typename: 'OrderItemSelection';
      readonly children: ReadonlyArray<
        {
          readonly __typename: 'OrderItemSelection';
          readonly children: ReadonlyArray<
            {
              readonly __typename: 'OrderItemSelection';
              readonly children: ReadonlyArray<
                {
                  readonly __typename: 'OrderItemSelection';
                  readonly children: ReadonlyArray<
                    { readonly __typename: 'OrderItemSelection' } & OrderItemFieldsFragmentFragment
                  >;
                } & OrderItemFieldsFragmentFragment
              >;
            } & OrderItemFieldsFragmentFragment
          >;
        } & OrderItemFieldsFragmentFragment
      >;
    } & OrderItemFieldsFragmentFragment
  >;
} & OrderItemFieldsFragmentFragment;

export type CustomerFragmentFragment = { readonly __typename: 'Customer'; readonly name: string };

export type OrderFragmentFragment = {
  readonly __typename: 'Order';
  readonly id: string;
  readonly ticketNumber: string;
  readonly timestamp: any;
  readonly deliveryStatus?: Maybe<DeliveryStatus>;
  readonly deliveryFeeCents?: Maybe<number>;
  readonly geographicalFeeCents?: Maybe<number>;
  readonly smallCartFeeCents?: Maybe<number>;
  readonly serviceFeeCents?: Maybe<number>;
  readonly tipCents?: Maybe<number>;
  readonly serviceMode: ServiceMode;
  readonly subtotal: number;
  readonly total: number;
  readonly tax: number;
  readonly paymentStatus: PaymentStatus;
  readonly customer: { readonly __typename: 'Customer' } & CustomerFragmentFragment;
  readonly items: ReadonlyArray<
    { readonly __typename: 'OrderItemSelection' } & OrderItemFragmentFragment
  >;
};

export type StoreFragmentFragment = {
  readonly __typename: 'Store';
  readonly id: string;
  readonly streetNumber: string;
};

export type PageInfoFragmentFragment = {
  readonly __typename: 'PageInfo';
  readonly startCursor?: Maybe<any>;
  readonly endCursor?: Maybe<any>;
  readonly hasNextPage: boolean;
  readonly hasPreviousPage: boolean;
};

export type CompleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompleteOrderMutation = {
  readonly __typename: 'Mutation';
  readonly completeOrder: { readonly __typename: 'Order' } & OrderFragmentFragment;
};

export type AcknowledgeOrdersMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type AcknowledgeOrdersMutation = {
  readonly __typename: 'Mutation';
  readonly acknowledgeOrders: ReadonlyArray<{ readonly __typename: 'Order'; readonly id: string }>;
};

export type SendHeartbeatMutationVariables = Exact<{
  storeId: Scalars['ID'];
}>;

export type SendHeartbeatMutation = {
  readonly __typename: 'Mutation';
  readonly sendHeartbeat: boolean;
};

export type GetOrdersQueryVariables = Exact<{
  storeId: Scalars['ID'];
  insertedAt: OrderDateRangeInput;
  status: OrderStatus;
}>;

export type GetOrdersQuery = {
  readonly __typename: 'Query';
  readonly orders: ReadonlyArray<{ readonly __typename: 'Order' } & OrderFragmentFragment>;
};

export type GetStoreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStoreQuery = {
  readonly __typename: 'Query';
  readonly store?: Maybe<{ readonly __typename: 'Store' } & StoreFragmentFragment>;
};

export type GetNewOrdersQueryVariables = Exact<{
  storeId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
}>;

export type GetNewOrdersQuery = {
  readonly __typename: 'Query';
  readonly newOrders: {
    readonly __typename: 'OrderConnection';
    readonly edges: ReadonlyArray<{
      readonly __typename: 'OrderEdge';
      readonly cursor: any;
      readonly node: { readonly __typename: 'Order' } & OrderFragmentFragment;
    }>;
    readonly pageInfo: { readonly __typename: 'PageInfo' } & PageInfoFragmentFragment;
  };
};

export type GetCompletedOrdersQueryVariables = Exact<{
  storeId: Scalars['ID'];
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
}>;

export type GetCompletedOrdersQuery = {
  readonly __typename: 'Query';
  readonly completedOrders: {
    readonly __typename: 'OrderConnection';
    readonly edges: ReadonlyArray<{
      readonly __typename: 'OrderEdge';
      readonly cursor: any;
      readonly node: { readonly __typename: 'Order' } & OrderFragmentFragment;
    }>;
    readonly pageInfo: { readonly __typename: 'PageInfo' } & PageInfoFragmentFragment;
  };
};

export type NewOrdersSubscriptionVariables = Exact<{
  storeId: Scalars['ID'];
  displayDeliveryFees?: Maybe<Scalars['Boolean']>;
}>;

export type NewOrdersSubscription = {
  readonly __typename: 'Subscription';
  readonly newOrders: ReadonlyArray<{ readonly __typename: 'Order' } & OrderFragmentFragment>;
};

export const CustomerFragmentFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    name
  }
`;
export const OrderItemFieldsFragmentFragmentDoc = gql`
  fragment OrderItemFieldsFragment on OrderItemSelection {
    product
    name
    price
    quantity
    type
    visible
    lineId
  }
`;
export const OrderItemFragmentFragmentDoc = gql`
  fragment OrderItemFragment on OrderItemSelection {
    ...OrderItemFieldsFragment
    children {
      ...OrderItemFieldsFragment
      children {
        ...OrderItemFieldsFragment
        children {
          ...OrderItemFieldsFragment
          children {
            ...OrderItemFieldsFragment
            children {
              ...OrderItemFieldsFragment
            }
          }
        }
      }
    }
  }
  ${OrderItemFieldsFragmentFragmentDoc}
`;
export const OrderFragmentFragmentDoc = gql`
  fragment OrderFragment on Order {
    id
    ticketNumber
    timestamp
    customer {
      ...CustomerFragment
    }
    deliveryStatus
    deliveryFeeCents
    geographicalFeeCents
    smallCartFeeCents
    serviceFeeCents
    tipCents
    serviceMode
    subtotal
    total
    tax
    items {
      ...OrderItemFragment
    }
    paymentStatus
  }
  ${CustomerFragmentFragmentDoc}
  ${OrderItemFragmentFragmentDoc}
`;
export const StoreFragmentFragmentDoc = gql`
  fragment StoreFragment on Store {
    id
    streetNumber
  }
`;
export const PageInfoFragmentFragmentDoc = gql`
  fragment PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const CompleteOrderDocument = gql`
  mutation CompleteOrder($id: ID!) {
    completeOrder(id: $id) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type CompleteOrderMutationFn = ApolloReactCommon.MutationFunction<
  CompleteOrderMutation,
  CompleteOrderMutationVariables
>;

/**
 * __useCompleteOrderMutation__
 *
 * To run a mutation, you first call `useCompleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrderMutation, { data, loading, error }] = useCompleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompleteOrderMutation,
    CompleteOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CompleteOrderMutation, CompleteOrderMutationVariables>(
    CompleteOrderDocument,
    options,
  );
}
export type CompleteOrderMutationHookResult = ReturnType<typeof useCompleteOrderMutation>;
export type CompleteOrderMutationResult = ApolloReactCommon.MutationResult<CompleteOrderMutation>;
export type CompleteOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompleteOrderMutation,
  CompleteOrderMutationVariables
>;
export const AcknowledgeOrdersDocument = gql`
  mutation AcknowledgeOrders($ids: [ID!]!) {
    acknowledgeOrders(ids: $ids) {
      id
    }
  }
`;
export type AcknowledgeOrdersMutationFn = ApolloReactCommon.MutationFunction<
  AcknowledgeOrdersMutation,
  AcknowledgeOrdersMutationVariables
>;

/**
 * __useAcknowledgeOrdersMutation__
 *
 * To run a mutation, you first call `useAcknowledgeOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeOrdersMutation, { data, loading, error }] = useAcknowledgeOrdersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcknowledgeOrdersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AcknowledgeOrdersMutation,
    AcknowledgeOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AcknowledgeOrdersMutation,
    AcknowledgeOrdersMutationVariables
  >(AcknowledgeOrdersDocument, options);
}
export type AcknowledgeOrdersMutationHookResult = ReturnType<typeof useAcknowledgeOrdersMutation>;
export type AcknowledgeOrdersMutationResult = ApolloReactCommon.MutationResult<
  AcknowledgeOrdersMutation
>;
export type AcknowledgeOrdersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcknowledgeOrdersMutation,
  AcknowledgeOrdersMutationVariables
>;
export const SendHeartbeatDocument = gql`
  mutation SendHeartbeat($storeId: ID!) {
    sendHeartbeat(storeId: $storeId)
  }
`;
export type SendHeartbeatMutationFn = ApolloReactCommon.MutationFunction<
  SendHeartbeatMutation,
  SendHeartbeatMutationVariables
>;

/**
 * __useSendHeartbeatMutation__
 *
 * To run a mutation, you first call `useSendHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHeartbeatMutation, { data, loading, error }] = useSendHeartbeatMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useSendHeartbeatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendHeartbeatMutation,
    SendHeartbeatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SendHeartbeatMutation, SendHeartbeatMutationVariables>(
    SendHeartbeatDocument,
    options,
  );
}
export type SendHeartbeatMutationHookResult = ReturnType<typeof useSendHeartbeatMutation>;
export type SendHeartbeatMutationResult = ApolloReactCommon.MutationResult<SendHeartbeatMutation>;
export type SendHeartbeatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendHeartbeatMutation,
  SendHeartbeatMutationVariables
>;
export const GetOrdersDocument = gql`
  query GetOrders($storeId: ID!, $insertedAt: OrderDateRangeInput!, $status: OrderStatus!) {
    orders(store: $storeId, insertedAt: $insertedAt, status: $status) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      insertedAt: // value for 'insertedAt'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export function useGetOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>;
export const GetStoreDocument = gql`
  query GetStore($id: ID!) {
    store(id: $id) {
      ...StoreFragment
    }
  }
  ${StoreFragmentFragmentDoc}
`;

/**
 * __useGetStoreQuery__
 *
 * To run a query within a React component, call `useGetStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStoreQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStoreQuery, GetStoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStoreQuery, GetStoreQueryVariables>(
    GetStoreDocument,
    options,
  );
}
export function useGetStoreLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetStoreQuery, GetStoreQueryVariables>(
    GetStoreDocument,
    options,
  );
}
export type GetStoreQueryHookResult = ReturnType<typeof useGetStoreQuery>;
export type GetStoreLazyQueryHookResult = ReturnType<typeof useGetStoreLazyQuery>;
export type GetStoreQueryResult = ApolloReactCommon.QueryResult<
  GetStoreQuery,
  GetStoreQueryVariables
>;
export const GetNewOrdersDocument = gql`
  query GetNewOrders(
    $storeId: ID!
    $first: Int
    $after: Cursor
    $displayDeliveryFees: Boolean = false
  ) {
    newOrders(
      store: $storeId
      first: $first
      after: $after
      displayDeliveryFees: $displayDeliveryFees
    ) {
      edges {
        node {
          ...OrderFragment
        }
        cursor
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${OrderFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`;

/**
 * __useGetNewOrdersQuery__
 *
 * To run a query within a React component, call `useGetNewOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewOrdersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      displayDeliveryFees: // value for 'displayDeliveryFees'
 *   },
 * });
 */
export function useGetNewOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetNewOrdersQuery, GetNewOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetNewOrdersQuery, GetNewOrdersQueryVariables>(
    GetNewOrdersDocument,
    options,
  );
}
export function useGetNewOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNewOrdersQuery,
    GetNewOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetNewOrdersQuery, GetNewOrdersQueryVariables>(
    GetNewOrdersDocument,
    options,
  );
}
export type GetNewOrdersQueryHookResult = ReturnType<typeof useGetNewOrdersQuery>;
export type GetNewOrdersLazyQueryHookResult = ReturnType<typeof useGetNewOrdersLazyQuery>;
export type GetNewOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetNewOrdersQuery,
  GetNewOrdersQueryVariables
>;
export const GetCompletedOrdersDocument = gql`
  query GetCompletedOrders(
    $storeId: ID!
    $last: Int
    $before: Cursor
    $displayDeliveryFees: Boolean = false
  ) {
    completedOrders(
      store: $storeId
      last: $last
      before: $before
      displayDeliveryFees: $displayDeliveryFees
    ) {
      edges {
        node {
          ...OrderFragment
        }
        cursor
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${OrderFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`;

/**
 * __useGetCompletedOrdersQuery__
 *
 * To run a query within a React component, call `useGetCompletedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedOrdersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      displayDeliveryFees: // value for 'displayDeliveryFees'
 *   },
 * });
 */
export function useGetCompletedOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompletedOrdersQuery,
    GetCompletedOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCompletedOrdersQuery, GetCompletedOrdersQueryVariables>(
    GetCompletedOrdersDocument,
    options,
  );
}
export function useGetCompletedOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompletedOrdersQuery,
    GetCompletedOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCompletedOrdersQuery, GetCompletedOrdersQueryVariables>(
    GetCompletedOrdersDocument,
    options,
  );
}
export type GetCompletedOrdersQueryHookResult = ReturnType<typeof useGetCompletedOrdersQuery>;
export type GetCompletedOrdersLazyQueryHookResult = ReturnType<
  typeof useGetCompletedOrdersLazyQuery
>;
export type GetCompletedOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetCompletedOrdersQuery,
  GetCompletedOrdersQueryVariables
>;
export const NewOrdersDocument = gql`
  subscription NewOrders($storeId: ID!, $displayDeliveryFees: Boolean) {
    newOrders(restaurantId: $storeId, displayDeliveryFees: $displayDeliveryFees) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useNewOrdersSubscription__
 *
 * To run a query within a React component, call `useNewOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrdersSubscription({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      displayDeliveryFees: // value for 'displayDeliveryFees'
 *   },
 * });
 */
export function useNewOrdersSubscription(
  baseOptions: ApolloReactHooks.SubscriptionHookOptions<
    NewOrdersSubscription,
    NewOrdersSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<NewOrdersSubscription, NewOrdersSubscriptionVariables>(
    NewOrdersDocument,
    options,
  );
}
export type NewOrdersSubscriptionHookResult = ReturnType<typeof useNewOrdersSubscription>;
export type NewOrdersSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  NewOrdersSubscription
>;
