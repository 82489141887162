/* eslint-disable */
import React, { Component, ReactNode } from 'react';

export class ErrorGuard extends Component<{ children: ReactNode }, { error: Error | undefined }> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  public componentDidCatch(error: any) {
    console.error(error);
    this.setState({ error });
  }

  public render() {
    if (this.state.error) {
      return (
        <>
          <h1>Error</h1>
          <button
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Reload
          </button>
        </>
      );
    }

    return <>{this.props.children}</>;
  }
}
