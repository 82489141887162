import styled from 'styled-components';

const marginTopScale = 0;

type DetailsContainerProps = { showDetails: boolean; height: number };

export const DetailsContainer = styled.div<DetailsContainerProps>`
  max-height: ${(p) =>
    p.showDetails ? `calc(${p.height}px + ${p.theme.scale(marginTopScale)})` : 0};
  transition: 0.4s;
  overflow: hidden;
`;
