import { datadogRum } from '@datadog/browser-rum';
import { Datacenter } from '@datadog/browser-core';

export const initDataDogRUM = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    datacenter: Datacenter.US,
    env: process.env.REACT_APP_STAGE,
    site: 'datadoghq.com',
    service: `${process.env.REACT_APP_BRAND}_tablet`,
    version: process.env.REACT_APP_VERSION,
  });
};
