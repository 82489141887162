/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';

export function register() {
  if (!('serviceWorker' in navigator)) {
    console.error('Service workers not supported - cannot provide updates or offline support');
    return;
  }

  // Can't serve assets if PUBLIC_URL is different to origin
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    return;
  }

  console.log(`Getting service worker from ${process.env.PUBLIC_URL}/service-worker.js`);
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then((reg) => {
    console.log('Service worker is being registered');
    // Check for new service worker on
    // worker already installed and page refresh
    if (!reg.installing) {
      console.log('Checking for new service worker immediately');
      reg.update();
    } else {
      console.log('Installing new service worker');
    }

    // Handle new service worker
    reg.addEventListener('updatefound', () => {
      console.log('Service worker update found event');
      const containerId = 'update-notification';
      const timeout = 1000 * 60;
      ReactDOM.render(<UpdateToastContainer />, document.querySelector(`#${containerId}`));
      toast.error(<UpdateToastContent />, {
        containerId,
        position: 'bottom-right',
        autoClose: timeout,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        draggable: false,
        onClick: () => {
          console.log('User acknowledged update - refreshing');
          window.location.href = '/';
        },
      });
      setTimeout(() => {
        console.log('User did did not acknowledge update in time - refreshing');
        window.location.href = '/';
      }, timeout);
    });
    // Check for a new service worker every 5 minutes
    window.setInterval(() => {
      console.log('Running service worker update interval');
      reg.update().catch((err) => {
        console.error('Error updating service worker', err);
      });
    }, 1000 * 30);
  });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        console.log('Un-registering service worker');
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message); /* eslint-disable-line */
      });
  }
}

const UpdateToastContent = () => (
  <>
    <ToastTitle>New update</ToastTitle>
    <ToastSubtext>Click to apply</ToastSubtext>
  </>
);

const UpdateToastContainer = styled(ToastContainer)`
  /* Disable closing of update notification */
  button[aria-label='close'] {
    display: none;
  }
`;

const ToastTitle = styled.p`
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const ToastSubtext = styled.p`
  margin: 10px;
  font-size: 12px;
`;
