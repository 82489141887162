import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.color.light['-3']};
  padding: ${(p) => p.theme.scale(0)};
  padding-bottom: 0;
`;
