import React, { createContext, useMemo, useEffect, useContext, useState, FC } from 'react';
import { OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { Spinner, PageSpinnerContainer } from 'src/components';
import { datadogRum } from '@datadog/browser-rum';

type AuthContext = {
  authClient: OktaAuth;
  user: UserClaims;
};

export const AuthContext = createContext<AuthContext>(null as any);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserClaims | undefined | 'ERROR'>();
  const [authState, setAuthState] = useState({ isPending: true, isAuthenticated: false });

  const authClient = useMemo(
    () =>
      new OktaAuth({
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
        issuer: process.env.REACT_APP_ISSUER,
        redirectUri: `${window.location.origin}/implicit/callback`,
        scopes: ['openid', 'profile', 'email', 'offline_access'],
        pkce: false,
        tokenManager: {
          autoRenew: true,
        },
      }),
    [],
  );

  useEffect(() => {
    // Subscribe to auth state on mount
    authClient.authStateManager.subscribe(setAuthState);

    // Update for initial render
    authClient.authStateManager.updateAuthState();

    // Check for changes to local storage every second
    const interval = window.setInterval(() => authClient.authStateManager.updateAuthState(), 1000);
    return () => {
      clearInterval(interval);
      authClient.authStateManager.unsubscribe(setAuthState);
    };
  }, [authClient, setAuthState]);

  // Handle token retrieval
  useEffect(() => {
    // Ignore if pending or authenticated
    if (authState.isPending || authState.isAuthenticated) {
      return;
    }

    // Store tokens if sourced from redirect
    if (authClient.token.isLoginRedirect()) {
      authClient.storeTokensFromRedirect().catch((err) => {
        console.error(err); // eslint-disable-line
        setUser('ERROR');
      });
      return;
    }

    authClient.token.getWithRedirect().catch((err) => {
      console.error(err); // eslint-disable-line
      setUser('ERROR');
    });
  }, [authState, authClient]);

  // Handle user object
  useEffect(() => {
    if (user || authState.isPending || !authState.isAuthenticated) {
      return;
    }

    // NOTE: Replace this with `authClient.getUser()` (HTTP request to Okta)
    // if required user attributes are not in JWT
    authClient.tokenManager
      .get('idToken')
      .then((token) => setUser(authClient.token.decode(token.value).payload))
      .catch((err) => {
        console.error(err); // eslint-disable-line
        setUser('ERROR');
      });
  }, [user, authState, authClient]);

  if (user === 'ERROR') {
    return (
      <>
        Error fetching user
        <a href="/">Reload</a>
      </>
    );
  }

  // Pending auth check / redirecting to login / fetching user
  if (authState.isPending || !authState.isAuthenticated || user === undefined) {
    return (
      <PageSpinnerContainer>
        <Spinner />
      </PageSpinnerContainer>
    );
  }

  datadogRum.setUser({
    id: user.UserID,
  });
  return <AuthContext.Provider value={{ authClient, user }}>{children}</AuthContext.Provider>;
};
