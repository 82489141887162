import styled from 'styled-components';

type SpinnerProps = {
  appearance?: 'light' | 'dark';
};

/** Spinner element */
export const Spinner = styled.div<SpinnerProps>`
  display: inline-block;
  box-sizing: content-box;
  position: relative;
  width: 1em;
  height: 1em;
  margin-bottom: -0.2em;
  border: solid 0.1em rgba(255, 255, 255, 0);
  border-radius: 50%;
  border-top-color: ${(p) =>
    p.appearance === 'light' ? p.theme.color.light['0'] : p.theme.color.dark['0']};
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Container for spinner element for use with page-level spinners.
 *
 * <PageSpinnerContainer>
 *   <Spinner />
 *   <MyText>Loading the things</MyText>
 * </PageSpinnerContainer>
 */
export const PageSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(p) => p.theme.scale(6)};

  > ${Spinner} {
    font-size: ${(p) => p.theme.scale(4)};
    margin-bottom: ${(p) => p.theme.scale(1)};
  }
`;
