/*
  -----------------------------------------------------------
  WARNING: DO NOT MANUALLY MODIFY THIS FILE!
  FILE GENERATED USING THE MARKETS CLI SCRIPT.
  IF YOU NEED TO ADD, REMOVE OR MODIFY MARKETS PLEASE
  NAVIGATE TO PROJECT ROOT FOLDER AND RUN `yarn regionalization`.
  -----------------------------------------------------------
  */
export type Currency = 'GBP' | 'CAD' | 'CHF' | 'ZAR';
export type RegionName = 'Great Britain' | 'Canada' | 'Switzerland' | 'South Africa';
export enum Regions {
  GB = 'GB',
  CA = 'CA',
  CH = 'CH',
  ZA = 'ZA',
}
export type RegionCode = 'GB' | 'CA' | 'CH' | 'ZA';
export enum RegionNames {
  GB = 'Great Britain',
  CA = 'Canada',
  CH = 'Switzerland',
  ZA = 'South Africa',
}
export enum RegionCurrencies {
  GB = 'GBP',
  CA = 'CAD',
  CH = 'CHF',
  ZA = 'ZAR',
}
export type Brands = 'bk' | 'plk' | 'th';
export type LanguageCode = 'en' | 'fr' | 'de' | 'it';
export type LanguageName = 'English' | 'French' | 'German' | 'Italian';
export type LocaleCode =
  | 'en-GB'
  | 'en-CA'
  | 'fr-CA'
  | 'en-CH'
  | 'de-CH'
  | 'fr-CH'
  | 'it-CH'
  | 'en-ZA';
export interface IMarket {
  regionCode: RegionCode;
  regionName: RegionName;
  regionCurrency: Currency;
  regionBrands: Brands[];
  regionLanguages: IMarketLanguage[];
}
export interface IMarketLanguage {
  languageCode: LanguageCode;
  languageLocale: LocaleCode;
  languageName: LanguageName;
}
export const MARKETS: IMarket[] = [
  {
    regionCode: 'GB',
    regionName: 'Great Britain',
    regionCurrency: 'GBP',
    regionBrands: ['bk'],
    regionLanguages: [{ languageCode: 'en', languageLocale: 'en-GB', languageName: 'English' }],
  },
  {
    regionCode: 'CA',
    regionName: 'Canada',
    regionCurrency: 'CAD',
    regionBrands: ['plk'],
    regionLanguages: [
      { languageCode: 'en', languageLocale: 'en-CA', languageName: 'English' },
      { languageCode: 'fr', languageLocale: 'fr-CA', languageName: 'French' },
    ],
  },
  {
    regionCode: 'CH',
    regionName: 'Switzerland',
    regionCurrency: 'CHF',
    regionBrands: ['plk', 'bk'],
    regionLanguages: [
      { languageCode: 'en', languageLocale: 'en-CH', languageName: 'English' },
      { languageCode: 'de', languageLocale: 'de-CH', languageName: 'German' },
      { languageCode: 'fr', languageLocale: 'fr-CH', languageName: 'French' },
      { languageCode: 'it', languageLocale: 'it-CH', languageName: 'Italian' },
    ],
  },
  {
    regionCode: 'ZA',
    regionName: 'South Africa',
    regionCurrency: 'ZAR',
    regionBrands: ['bk'],
    regionLanguages: [{ languageCode: 'en', languageLocale: 'en-ZA', languageName: 'English' }],
  },
];
export const LOCALES: LocaleCode[] = [
  'en-GB',
  'en-CA',
  'fr-CA',
  'en-CH',
  'de-CH',
  'fr-CH',
  'it-CH',
  'en-ZA',
];
export const LANGUAGE_CODES: LanguageCode[] = ['en', 'fr', 'de', 'it'];
export const CURRENCIES: Currency[] = ['GBP', 'CAD', 'CHF', 'ZAR'];
export const REGIONS: RegionCode[] = ['GB', 'CA', 'CH', 'ZA'];
export const REGION_NAMES: RegionName[] = [
  'Great Britain',
  'Canada',
  'Switzerland',
  'South Africa',
];
export const BRANDS: Brands[] = ['bk', 'plk', 'th'];
